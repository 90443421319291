<template>
  <div class="message-con">
    <NavBar title="留言" left-arrow @click-left="goBack" v-if="isWeixin_status == false"></NavBar>
    <Field  
      v-model="message"
      rows="6"
      autosize
      label="留言反馈"
      type="textarea"
      maxlength="50"
      placeholder="请输入留言信息"
      show-word-limit>
    </Field>
  </div>
</template>

<script>
import { NavBar, Field  } from 'vant';
export default {
  components: {
    NavBar, Field
  },
  data() {
    return {
      message: ""
    }
  }
}
</script>
<style lang="scss" scoped>
.message-con {
  width: 100%;
  height: 100%;
}
</style>